import React from "react"
import Layout from "react"
import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import HomeBody from "../../components/home/HomeBody"
import Footer from "../../components/footer/Footer"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import Img from "gatsby-image"
import { useStaticQuery, graphql, useQuery } from "gatsby"

import ClergySchedule from "../../../static/pdf/service-schedule/current_clergy_schedule.pdf" 



import "../../styles/global.css"
import "../../styles/services.css"
import "../../styles/menu.css"



function MyApp () {


	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/service-schedule/*.jpg" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid_noBase64
	                }
	              }
	            }
	          }
	        }
			}
	`


	const images = useStaticQuery(query).allFile
	const iconImage = images.edges.find(element => element.node.name === 'icon').node
	const panoramaImage = images.edges.find(element => element.node.name === 'panorama').node

	return (
			<div className="site body">
			<HeaderMinimal />
				<MainMenu />
				<div className="line-across menu-line-across"></div>
				<main className="site-content service-schedule">
				<div>
						<div className="services-image-wrapper">
							<Img 		           		
								fluid={iconImage.childImageSharp.fluid}
 								className="services-image" alt="Logo" 
 							/>
						</div>
													
							<div className="weekly-services">
								<div>
									<h4>Weekly Service Schedule</h4>
									
								</div>
								<div className="service-day">
									Saturday Evening Vespers - 7:15pm
								</div>

								<div className="service-day">
									Sunday Morning Matins/Orthros - 9:15am
								</div>

								<div className="service-day">
									<b>Sunday Morning Divine Liturgy - 10:00am</b> 
								</div>

								<div className="service-day">
									Weekday Morning Prayers (During School Year) - 8:15am
								</div>

								<div className="service-day">
									Wednesday Evening Prayer Service - See calendar for specific service and time.
								</div>

								<div>
								<h5>All are Welcome!</h5>
									<p>
										Please see the weekly church bulletin for Feast Day Services and for Services During Lent and other special Seasons.
									</p>
								</div>
								<div className="see-below">
									<h3>See below for a brief description of Services.</h3>
								</div>

							</div>
							</div>
							<div className="panorama-image-wrapper">
								<h5>A panorama view of the interior (Nave) of Saint John Cathedral decorated for Christmas Eve.</h5>
								<Img 		           		
									fluid={panoramaImage.childImageSharp.fluid}
									className="panorama-image" alt="Logo" 
								/>
							</div>
							
							<h4>Description of Services for Visitors</h4>

							<p><b>Vespers</b> is an evening prayer service that usually lasts less than an hour and is made up of Psalms, hymns, and short verses sung by the chanters. It can be a good introduction to Orthodox worship.</p>

<p><b>Matins</b> is a morning prayer service, which on Sunday flows without interruption into the main Sunday service, the Divine Liturgy. Matins is also known by the Greek name, Orthros. Many psalms and variable hymns are sung by the chanters, allowing it to expound on the themes for the day, which on Sunday is usually the Resurrection of Christ. </p>
<p><b>Divine Liturgy</b> is the main, Sunday Service. The first part of the Liturgy focuses on the singing of hymns and the reading of the Epistle and the Gospel for the day, followed by the homily or short sermon. The second part of the Liturgy centers around offering prayers of thanksgiving to God and receiving Holy Communion. Everyone is welcome to join us in the hymns and prayers, but only Orthodox Christians who have prepared themselves should receive Communion. Everyone may partake of the blessed bread (antidoron) that is offered at the end of Liturgy. Also you are welcome on Sundays to join us after Liturgy for “Coffee Hour” and snacks in the Cathedral basement. </p>
<p>There are many other special and beautiful services celebrated in the tradition of the Orthodox Church. Days and times for these can be found on the <a href="https://calendar.google.com/calendar/embed?src=0k3i98qf8faoh4893emkkhed7c%40group.calendar.google.com&ctz=America%2FAnchorage" target="_blank">Church Calendar</a>.</p>

<p className="congregational-singing"><b>At Saint John’s we have the custom of singing many of the Orthodox hymns congregationally. The order and text for most of these services can be found in books and hymn sheets set out in the entryway (or Narthex). Please feel free to use these books as much or as little as you wish. We welcome you to worship with us.</b></p>
							<div className='download-clergy-schedule-wrapper'>
								<a className="download-btn download-clergy-schedule" href={ClergySchedule} target="_blank">
									Download Clergy Serving Schedule
								</a> 
							</div>	  			   
					</main>
				<Footer />
			</div>
	)
}

export default MyApp 
